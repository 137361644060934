@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Voces&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Hind:wght@300;400;500;600;700&family=Lato:wght@100;300;400;700;900&display=swap');

h4 {
  font-size: 1.25rem !important;
  text-transform: lowercase;
  font-weight: 700;
}

.subheader {
  font-size: 2rem !important;
  text-transform: lowercase;
  font-weight: 600;
}