@import url(https://fonts.googleapis.com/css2?family=Poiret+One&family=Voces&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Hind:wght@300;400;500;600;700&family=Lato:wght@100;300;400;700;900&display=swap);
h4 {
  font-size: 1.25rem !important;
  text-transform: lowercase;
  font-weight: 700;
}

.subheader {
  font-size: 2rem !important;
  text-transform: lowercase;
  font-weight: 600;
}
.header-container-1 {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  left: 0;
  /* background-color: rgba(255,255,255,0.3); */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.white-active {
  background-color: #f7f6f1;
  transition: 0.5s ease-in-out;
}

.null {
  background-color: unset;
  transition: 0.5s ease-in-out;
}

.navbar-brand {
  font-family: 'Hind', cursive;
  font-size: 1.2rem !important;
  color:  #4923F3 !important;
  transition: .5s ease-in-out;
}

.nav-link {
  font-family: 'Hind', cursive;
  font-size: 1rem !important;
  color:  #4923F3 !important;
  transition: .5s ease-in-out;
  border-bottom: 1px solid #4923F3;
}

.MuiSvgIcon-root {
  fill: #4923F3 !important;
  height: 1.5em !important;
  width: 1.5em !important;
}


.navbar-toggler:focus {
  box-shadow: none !important;
}


.active .navbar-brand {
  color: #4923F3 !important;
}

.active .nav-link {
  color: #4923F3 !important;
  border-bottom: 1px solid #4923F3;
  position: relative;
  z-index: 10;
}

.active .MuiSvgIcon-root {
  fill: #4923F3 !important;
}


/* // font-family: 'Abril Fatface', cursive;
// font-family: 'Hind', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Poiret One', cursive; */

.home-container {
  /* background-image: url('../images/marble-2.jpg'); */
  background-color: #EDDFD8; 
}

.home-wrapper {
  max-width: 1400px !important;
  height: 75vh;
}

h1 {
  font-family: 'Hind', cursive;
  font-size: 1.5rem;
  color: #4923F3;
}

p, .purple {
  color: #4923F3 !important;
}

@media(min-width: 992px) {
  h1 {
    font-size: 3rem;
  }
}

p {
  font-family: 'Voces', cursive;
}

.center-img {
  max-width: 200px;
}

.experience-header {
  background-color: #f7f6f1;
  margin-bottom: -5px;
  font-family: 'Hind', cursive;
  font-size: 1.5rem;
}

.experience-header h3 {
  max-width: 1400px;
}

.more-about h1, a {
  text-align: center;
  text-decoration: none !important;
}
.project-container {
  background-color: #000000;
  position: relative;
}

.project-container img {
  height: 100%;
  object-fit: cover;
}

.hide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 100;
  transition: ease-in-out opacity 0.5s;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #4923F3;
  text-align: center;
  padding-top: 25%;
}


.hide:hover {
  opacity: 100;
}
 
.site-btn {
  width: 100%;
  max-width: 200px;
  color: #193532;
  font-family: 'Hind', cursive;
  text-align: center;
  border: 2px solid #193532;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1.25rem;
  transition: .25s ease-in-out;
}

.site-btn:hover {
  color: #ffffff !important;
  /* background-color: #193532; */
}

.project-wrapper {
  max-width: 1400px !important;
}

.project-image {
  /* max-width: 320px !important; */
  width: 100%;
}

@media(min-width: 768px) {
 .project-image {
   max-width: 500px !important;
 }

 .hide {
  opacity: 0;
 }
}

@media(min-width: 992px) {
  .project-image {
    max-width: unset !important;
  }
 }

.resume-container {
  background-color: #f7f6f1;
  
}

.resume-header {
  color: #4923F3;
}

.resume-wrapper {
  max-width: 1400px !important;
}

.resume-container a, h4 {
  color:#4923F3 !important;
}

.purple {
  color:#4923F3 !important;
}

.headshot-wrapper {
  max-width: 1400px;
}

.headshot {
  max-width: 250px !important;
  border-radius: 5px;
}
.about-container {
  /* background-image: url('../images/marble-2.jpg'); */
  background-color: #193532;
}

.about-wrapper {
  max-width: 1400px !important;
  height: 75vh;
}

.about-wrapper > h1 {
  font-family: 'Hind', cursive;
  /* font-size: 1.5rem; */
  color: #f7f6f1;

}

@media(min-width: 992px) {
  .about-wrapper > h1 {
    font-size: 3rem;
  }
}
.portfolio-container {
    background-color: black;
}

.portfolio-container h4, p, a {
    color: #ffffff !important;
    font-family: 'Hind';
}

.gobackbtn {
    background-color: #4923F3;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 20%);
}


.hero-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 60vh;
    position: relative;
}

video {
    height: 100%;
    width: 100%;
}

.pdf a {
    color: #ffffff !important;
    font-family: 'Hind';
    text-align: center;
}

.gallery {
    columns: 3;
    padding: 0 12px;
}

.gallery .pics {
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
}

/* fix this to be like the home page */

@media (max-width: 991px) {
    .gallery {
        columns: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
        columns: 1;
    }
}
.hide-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: ease-in-out opacity 0.5s;
    z-index: 1000;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    color: #4923F3;
    text-align: center;
    padding-top: 25%;
    overflow-y: scroll
  }
  
  .hide-item:hover {
    opacity: 100 !important;
  }

  .hide p {
    font-size: 1.2rem !important;
    color:  #4923F3 !important;
  }
.footer-container {
  background-color: #4923F3;
}


span {
  color: #f7f6f1;
}

.footer-text {
  color: #f7f6f1 !important;
  text-decoration: none;
  font-size: 0.75rem !important;
}
