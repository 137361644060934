.header-container-1 {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  left: 0;
  /* background-color: rgba(255,255,255,0.3); */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.white-active {
  background-color: #f7f6f1;
  transition: 0.5s ease-in-out;
}

.null {
  background-color: unset;
  transition: 0.5s ease-in-out;
}

.navbar-brand {
  font-family: 'Hind', cursive;
  font-size: 1.2rem !important;
  color:  #4923F3 !important;
  transition: .5s ease-in-out;
}

.nav-link {
  font-family: 'Hind', cursive;
  font-size: 1rem !important;
  color:  #4923F3 !important;
  transition: .5s ease-in-out;
  border-bottom: 1px solid #4923F3;
}

.MuiSvgIcon-root {
  fill: #4923F3 !important;
  height: 1.5em !important;
  width: 1.5em !important;
}


.navbar-toggler:focus {
  box-shadow: none !important;
}


.active .navbar-brand {
  color: #4923F3 !important;
}

.active .nav-link {
  color: #4923F3 !important;
  border-bottom: 1px solid #4923F3;
  position: relative;
  z-index: 10;
}

.active .MuiSvgIcon-root {
  fill: #4923F3 !important;
}

