.resume-container {
  background-color: #f7f6f1;
  
}

.resume-header {
  color: #4923F3;
}

.resume-wrapper {
  max-width: 1400px !important;
}

.resume-container a, h4 {
  color:#4923F3 !important;
}

.purple {
  color:#4923F3 !important;
}

.headshot-wrapper {
  max-width: 1400px;
}

.headshot {
  max-width: 250px !important;
  border-radius: 5px;
}