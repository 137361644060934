.portfolio-container {
    background-color: black;
}

.portfolio-container h4, p, a {
    color: #ffffff !important;
    font-family: 'Hind';
}

.gobackbtn {
    background-color: #4923F3;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 20%);
}


.hero-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 60vh;
    position: relative;
}

video {
    height: 100%;
    width: 100%;
}

.pdf a {
    color: #ffffff !important;
    font-family: 'Hind';
    text-align: center;
}

.gallery {
    columns: 3;
    padding: 0 12px;
}

.gallery .pics {
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
}

/* fix this to be like the home page */

@media (max-width: 991px) {
    .gallery {
        columns: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
        columns: 1;
    }
}
.hide-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: ease-in-out opacity 0.5s;
    z-index: 1000;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    color: #4923F3;
    text-align: center;
    padding-top: 25%;
    overflow-y: scroll
  }
  
  .hide-item:hover {
    opacity: 100 !important;
  }

  .hide p {
    font-size: 1.2rem !important;
    color:  #4923F3 !important;
  }