/* // font-family: 'Abril Fatface', cursive;
// font-family: 'Hind', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Poiret One', cursive; */

.home-container {
  /* background-image: url('../images/marble-2.jpg'); */
  background-color: #EDDFD8; 
}

.home-wrapper {
  max-width: 1400px !important;
  height: 75vh;
}

h1 {
  font-family: 'Hind', cursive;
  font-size: 1.5rem;
  color: #4923F3;
}

p, .purple {
  color: #4923F3 !important;
}

@media(min-width: 992px) {
  h1 {
    font-size: 3rem;
  }
}

p {
  font-family: 'Voces', cursive;
}

.center-img {
  max-width: 200px;
}

.experience-header {
  background-color: #f7f6f1;
  margin-bottom: -5px;
  font-family: 'Hind', cursive;
  font-size: 1.5rem;
}

.experience-header h3 {
  max-width: 1400px;
}

.more-about h1, a {
  text-align: center;
  text-decoration: none !important;
}