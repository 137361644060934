.footer-container {
  background-color: #4923F3;
}


span {
  color: #f7f6f1;
}

.footer-text {
  color: #f7f6f1 !important;
  text-decoration: none;
  font-size: 0.75rem !important;
}