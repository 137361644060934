.project-container {
  background-color: #000000;
  position: relative;
}

.project-container img {
  height: 100%;
  object-fit: cover;
}

.hide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 100;
  transition: ease-in-out opacity 0.5s;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  color: #4923F3;
  text-align: center;
  padding-top: 25%;
}


.hide:hover {
  opacity: 100;
}
 
.site-btn {
  width: 100%;
  max-width: 200px;
  color: #193532;
  font-family: 'Hind', cursive;
  text-align: center;
  border: 2px solid #193532;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1.25rem;
  transition: .25s ease-in-out;
}

.site-btn:hover {
  color: #ffffff !important;
  /* background-color: #193532; */
}

.project-wrapper {
  max-width: 1400px !important;
}

.project-image {
  /* max-width: 320px !important; */
  width: 100%;
}

@media(min-width: 768px) {
 .project-image {
   max-width: 500px !important;
 }

 .hide {
  opacity: 0;
 }
}

@media(min-width: 992px) {
  .project-image {
    max-width: unset !important;
  }
 }
