.about-container {
  /* background-image: url('../images/marble-2.jpg'); */
  background-color: #193532;
}

.about-wrapper {
  max-width: 1400px !important;
  height: 75vh;
}

.about-wrapper > h1 {
  font-family: 'Hind', cursive;
  /* font-size: 1.5rem; */
  color: #f7f6f1;

}

@media(min-width: 992px) {
  .about-wrapper > h1 {
    font-size: 3rem;
  }
}